import { Asset } from './asset';
import { Filter } from './filter';
// @ts-ignore
import { LABEL_ABBREVIATIONS } from '@/utilities/label/label-abbreviation-constants';
import AssetSorting from '@/utilities/asset/asset-sorting';

class LayerSet {
  identifier: string;
  layerSetAssets: Asset[];
  isVisible = false;

  get filter(): Filter {
    return JSON.parse(this.identifier);
  }

  get isLoading(): boolean {
    return this.layerSetAssets.some(asset => asset.isLoading);
  }

  get IsGeometryLayer(): boolean {
    return this.layerSetAssets.some(asset => asset.simulationResult.layer_type === 'Geometry');
  }

  get showByDefault(): boolean {
    return this.layerSetAssets.some(asset => asset.simulationResult.show_by_default === true);
  }

  get abbreviatedLabel(): string {
    if (this.IsGeometryLayer) {
      let labelValues : string[] = [];
      this.layerSetAssets.forEach(asset =>
        labelValues.push(
          asset.simulationResult.filename.substring(
            0, asset.simulationResult.filename.lastIndexOf('.')
          )
        )
      );
      return labelValues.join(' · ');
    } else {
      return this.abbreviatedLabelFor(this.filter);
    }

  }

  get layerType() : string {
    //this makes an assumption that all assets grouped into a layer set are of the same layer type.  Currently is is a safe assumption as the UI allows a user to view
    //mutliple layer sets at once as long as they are of different layer types.  In order to do this, layer_type must be a property that layer sets are grouped by.
    return this.layerSetAssets.length > 0 ? this.layerSetAssets[0].simulationResult.layer_type : '';
  }

  get completeLabel(): string {
    if (this.layerSetAssets.some(asset => asset.simulationResult.layer_type === 'Geometry')) {
      let labelValues : string[] = [];
      labelValues.push(this.identifier);
      labelValues.push(this.abbreviatedLabel);
      return labelValues.join(': ');
    } else {
      let labelValues : string[] = [];

      let filter = JSON.parse(this.identifier);
      let filterProperties = Object.keys(filter).sort((a, b) =>  AssetSorting.GetSortOrder(a) - AssetSorting.GetSortOrder(b));
      filterProperties.forEach(property => {
        if (property !== 'layer_type') {
          if (property === 'qualifier') {
            //for qualifiers, take each one separately and create an abbreviation for it
            filter[property].split(' · ').forEach((word: string) => labelValues.push(word));
          } else{
            //for other properties, abbreviate the label as a whole
            labelValues.push(filter[property]);
          }
        }
      });

      return labelValues.join(' · ');
    }
  }

  constructor(identifier: string, assets: Asset[]) {
    this.identifier = identifier;
    this.layerSetAssets = assets;
  }

  abbreviatedLabelFor(filter: Filter) {
    class InputKey {
      property: string;
      value: string;

      constructor(property: string, value: string) {
        this.property = property;
        this.value = value.toString().toLowerCase();
      }
    }

    let input : InputKey[] = [];

    let filterProperties = Object.keys(filter).sort((a, b) =>  AssetSorting.GetSortOrder(a) - AssetSorting.GetSortOrder(b));
    filterProperties.forEach(property => {
      if (property !== 'layer_type') {
        if (property === 'qualifier') {
          //for qualifiers, take each one separately and create an abbreviation for it
          filter[property].split(' · ').forEach(word => input.push(new InputKey(property, word)));
        } else{
          //for other properties, abbreviate the label as a whole
          input.push(new InputKey(property, filter[property]));
        }
      }
    });

    let abbreviationLabels : string[] = [];
    input.forEach(input_key => {
      let abbreviation: string;
      let special_case_time_filter_regex = '^([01]?[0-9]|2[0-3]):[0-5][0-9]-([01]?[0-9]|2[0-3]):[0-5][0-9]$';  //matches a time range like hh:mm-hh:mm (assumes hh is 24 hour time)
      //first check if the property name as a whole is abbreviated (e.g. Thermal Comfort = TC) and if not, then try each word separately (e.g. Thermal & Comfort)
      if (input_key.property == 'single_date') {
        //spcial case for the 'single_date' property in solar daylight.  Shorted the date and display it as the abbreviation
        abbreviation = input_key.value.replaceAll('-', '');
      } else if (input_key.property == 'time_filter' && input_key.value.match(special_case_time_filter_regex) != null) {
        //special case to handle time_range filters formatted as hh:mm-hh:mm
        abbreviation = input_key.value;
        //abbreviate as hh-hh
        // let times = input_key.value.split('-');
        // for (let time of times) {
        //   let hour = time.split(':')[0];
        //   abbreviation += `${hour}-`;
        // }
        // abbreviation = abbreviation.slice(0, -1);

      } else {
        //regular process
        let indexOfColon = input_key.value.indexOf(':');
        if(indexOfColon !== -1) input_key.value = input_key.value.slice(0, indexOfColon); // remove everything after and including ':'
        abbreviation = LABEL_ABBREVIATIONS[input_key.value]; //get the abbreviation from the constant for the whole key
        if (!abbreviation) { //if it doesn't exist, break into multiple parts and look at each
          let abbreviationsForWord : string[] = [];
          const words = input_key.value.split(' ');  //split string into substrings by a space
          words.forEach(word => {  //foreach substring in the key
            let abbreviationForWord;
            if (/^\d+$/.test(word)) {  //if substring consists of only digits
              abbreviationForWord = word;  //skip abbreviation step and use original substring as abbreviation
            } else {
              abbreviationForWord = LABEL_ABBREVIATIONS[word]; //check if there's an abbreviation for the substring
              if (!abbreviationForWord) {  //if not, grab the first 3 characters as the abbreviation
                abbreviationForWord = this.abbreviateToMaxCharacters(word, 3);
              }
            }
            abbreviationsForWord.push(abbreviationForWord);  //add abbreviation (or original substring) to list
          });

          abbreviation = this.abbreviateToMaxCharacters(abbreviationsForWord.join(' '), 7);  //now, if there were multiple words in the key, there are multiple abbrevations.  Concatenate them and then truncate to 7 characters max
        }
      }

      abbreviationLabels.push(abbreviation);
    });

    return this.labelStringFor(abbreviationLabels);
  }

  labelStringFor(labels: string[]) {
    return labels
      ?.filter(label => label)
      .join(' · ');
  }

  abbreviateToMaxCharacters(input: string, abbreviationLength : number) {
    if (input.length > abbreviationLength) {
      input = input.substring(0, abbreviationLength);
      input = input.trim();
    }

    return input;
  }
}

export {
  LayerSet
};
